import { DragulaModule } from 'ng2-dragula';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeZhCN from '@angular/common/locales/zh-Hans';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localeRu from '@angular/common/locales/ru';
import localePtBR from '@angular/common/locales/pt';
import localeAr from '@angular/common/locales/ar';
import localeEnIN from '@angular/common/locales/en-IN';
import localeEnGB from '@angular/common/locales/en-GB';

registerLocaleData(localeEn, 'en-US');
registerLocaleData(localeFr, 'fr-FR');
registerLocaleData(localeDe, 'de-DE');
registerLocaleData(localeEs, 'es-ES');
registerLocaleData(localeZhCN, 'zh-CN');
registerLocaleData(localeJa, 'ja-JP');
registerLocaleData(localeKo, 'ko-KR');
registerLocaleData(localeRu, 'ru-RU');
registerLocaleData(localePtBR, 'pt-BR');
registerLocaleData(localeAr, 'ar-SA');
registerLocaleData(localeEnIN, 'en-IN');
registerLocaleData(localeEnGB, 'en-GB');


import { CurrencyLocalePipe } from '../../helpers/currency-locale.pipe';
import { DateTimePipe } from '../../helpers/date-time.pipe';
import { PermissionPipe } from '../../helpers/permission.pipe';
import { StringPipe } from '../../helpers/string.pipe';
import { SkeletonLoaderComponent } from './skeleton-loader/skeleton-loader.component';
import { SafePipe } from '../../helpers/safe.pipe';
import { RouterModule } from '@angular/router';
import { NgbAccordionModule, NgbCarouselModule, NgbDropdownModule, NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { FullCalendarModule } from '@fullcalendar/angular';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS, MatNativeDateModule, MatRipple } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DataTablesModule } from 'angular-datatables';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { BaseChartDirective } from 'ng2-charts';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CUSTOM_DATE_FORMATS } from '../../data-models/my-date-formats';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
    imports: [
        CommonModule,
        DatePipe,
        CurrencyPipe,
        DateTimePipe,
        CurrencyLocalePipe,
        StringPipe,
        SkeletonLoaderComponent,
        PermissionPipe,
        RouterModule,
        NgbAccordionModule,
        NgbNavModule,
        NgbCarouselModule,
        ImageCropperComponent,
        FullCalendarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatIconModule,
        MatFormFieldModule,
        MatRadioModule,
        MatProgressBarModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatAutocompleteModule,
        NgbDropdownModule,
        CarouselModule,
        SweetAlert2Module.forRoot(),
        DataTablesModule,
        SweetAlert2Module,
        NgbModule,
        InfiniteScrollDirective,
        DragulaModule.forRoot(),
        SafePipe,
        BaseChartDirective,
        MatRipple,
        MatExpansionModule,
        MatCardModule,
        MatCheckboxModule,
        MatSelectModule,
        PdfViewerModule,
        MatTooltipModule,
    ],
    declarations: [
    ],
    exports: [
      CommonModule,
      DatePipe,
      CurrencyPipe,
      DateTimePipe,
      CurrencyLocalePipe,
      StringPipe,
      SkeletonLoaderComponent,
      PermissionPipe,
      RouterModule,
      NgbAccordionModule,
      NgbNavModule,
      NgbCarouselModule,
      ImageCropperComponent,
      FullCalendarModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatIconModule,
      MatFormFieldModule,
      MatRadioModule,
      MatProgressBarModule,
      FormsModule,
      ReactiveFormsModule,
      MatInputModule,
      MatAutocompleteModule,
      NgbDropdownModule,
      CarouselModule,
      DataTablesModule,
      SweetAlert2Module,
      NgbModule,
      InfiniteScrollDirective,
      DragulaModule,
      SafePipe,
      BaseChartDirective,
      MatRipple,
      MatExpansionModule,
      MatCardModule,
      MatCheckboxModule,
      MatSelectModule,
      PdfViewerModule,
      MatTooltipModule,
    ],
    providers:[
      { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    ]
})

export class SharedModule { }
// , NgbAccordionModule, NgbNavModule, NgbCarouselModule
