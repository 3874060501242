/* eslint-disable @typescript-eslint/no-inferrable-types */
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LoaderService } from '../../../services/loader.service';

@Component({
  selector: 'app-skeleton-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './skeleton-loader.component.html',
  styleUrl: './skeleton-loader.component.scss'
})
export class SkeletonLoaderComponent {
  @Input() width = 400;
  @Input() height = 100;
  @Input() type: string = 'square';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loading$ : any = ""
  constructor(private loadingService: LoaderService) {
    this.loading$ = this.loadingService.loading$;
  }

}
