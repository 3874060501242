/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environments';

import * as CryptoJS from 'crypto-js';
const SECRET_KEY = `${environment.secret_key}`;



@Injectable({
  providedIn: 'root'
})
export class StorageService {

  /**
   * Encodes the string value to be then saved in the session storage.
   *
   * @param txt string: value to be encoded.
   * @returns
   */
    encrypt(txt: any): string {
      txt = JSON.stringify(txt)
      return CryptoJS.AES.encrypt(txt, SECRET_KEY).toString();
    }

  /**
   * Decodes the encoded data from session storage.
   *
   * @param txtToDecrypt
   * @returns
   */
  decrypt(txtToDecrypt: string) {
    return CryptoJS.AES.decrypt(txtToDecrypt, SECRET_KEY).toString(CryptoJS.enc.Utf8);
  }

  /**
   * Encode and save a value to the session storage.
   *
   * @param key string: key value, used to recall the value.
   * @param value string/json: value to be encoded and saved.
   */
  setJsonValue(key: string, value: any) {
    const data = {
      "value": value
    }

    localStorage.setItem(key, this.encrypt(data));
  }

  /**
   * Decode and return a value from the session storage.
   *
   * @param key string: key value, used to recall the value.
   * @returns
   */
  getJsonValue(key: string) {
    const data = localStorage.getItem(key) || "";
    if (data) {
      const json = JSON.parse(this.decrypt(data));
      return json.value;
    }
    return ""
  }

  /**
   * Clear the session storage.
   */
  clearToken() {
    localStorage.clear();
  }

  /**
   * Returns a random integer between min (inclusive) and max (inclusive).
   * The value is no lower than min (or the next integer greater than min
   * if min isn't an integer) and no greater than max (or the next integer
   * lower than max if max isn't an integer).
   * Using Math.round() will give you a non-uniform distribution!
   */
  getRandomInt(min: any, max: any) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }


  /**
 * Generate a salt.
 *
 * @returns salt.
 */
  getSalt() {
    // const randomBytes = require('randombytes');
    // const byte = randomBytes(16);//get 16 random bytes
    const re = new Uint8Array(16);
    for (let i = 0; i < 16; i++) {
      re[i] = this.getRandomInt(0, 128)
    }
    return re
  }
}
