/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-wrapper-object-types */
export interface UserSuperData {
    _id: String,
    basic_information: BasicInformation,
    profile_type: String[],
    user_name: String,
    privacy: Privacy,
    count: Count,
    is_verified: boolean,
    ready_for_eval: boolean,
    referral_code: String,
    active_status: String,
    rating: number,
}


export interface BasicInformation {
    name: Name,
    bio_desc: String,
    profile_headline: String,
    cover_photo: String,
    dp: String,
    email: String,
    dob: String,
    gender: String,
    location: Location,
    address: Address[],
}

export interface Name {
    first_name: String,
    middle_name: String,
    last_name: String,
    display_name: String,
}

export interface Location {
    location_name: String,
    longitude: String,
    latitude: String,
    locdat: LocationData,
}

export interface Address {
    country_id: String,
    addr1: String,
    addr2: String,
    state: String,
    city: String,
    district: String,
    pincode: String,
}

export interface LocationData {
    description: String,
    latitude: String,
    longitude: String,
    streetName: String,
    streetNumber: String,
    route: String,
    neighbourhood: String,
    subLocalityLevel1: String,
    subLocality: String,
    locality: String,
    administrativeAreaLevel1: String,
    administrativeAreaLevel2: String,
    country: String,
    postalCode: String,
    political: String,
}

export interface Privacy {
    follow_privacy: boolean,
    profile_privacy: String,
    profile_privacy_name: String,
    email_privacy: String,
    post_privacy: String,
    phone_no_privacy: String,
}

export interface LOCALE_INFO {
  currency: string,
  currency_format: string,
  date: string,
  locale: string,
  region: string,
  timezone: string,
  country: string,
  time: string,
  phone: string,
  unit: string,
}


export interface Count {
    follower_count: number,
    post_count: number,
    following_count: number,
    rating_count: number,
    assessment_count: number,
}

export interface ProfileType {
    profile_type: string,
}

export interface UserFetchResult {
    data: UserSuperData,
    response: String,
}

export const USER_SUPER = 'USD'
export const PARENT_USER_SUPER = 'PUSD'
export const USER_NAME = 'UN'
export const PARENT_USER_NAME = 'PUN'
export const USER_EMAIL = 'UEMA'
export const USER_DP = 'UDP'
export const PARENT_USER_DP = 'PUDP'
export const USER_DISPLAY_NAME = 'UDN'
export const PARENT_USER_DISPLAY_NAME = 'PUDN'
export const USER_ID = 'UID'
export const PARENT_USER_ID = 'PUID'
export const ACTIVITY = 'ACT'
export const CLASS = 'CLA'
export const SESSION_ID = 'SID'
export const ROLE = 'RINFO'
export const LOGIN_INFO = 'LOGIN_INFO'
export const PASSWORD_OTP = 'FPV'
export const USER_ACADEMIES = 'UAL'
export const USER_ACADEMY_ID = 'UAID'
export const USER_CLASSES = 'UCL'
export const USER_CLASS_ID = 'UCID'
export const USER_CLASS_INFO = 'UCINFO'
export const PARENT_LOGIN = 'PLOGIN'

export const ASSESSMENTS = 0
export const PERSONAL_SESSIONS = 1
export const SESSIONS = 2
export const EDUCATION = 3
export const HOMEWORK = 4

export const ASSESSMENT_TYPE_1 = 'Physical'
export const ASSESSMENT_TYPE_2 = 'Technical'
export const ASSESSMENT_TYPE_3 = 'Psychological'
export const ASSESSMENT_TYPE_4 = 'Tactical'

export const POST_TYPE_IMG = 'image'
export const POST_TYPE_VIDEO = 'video'
export const POST_TYPE_DOC = 'application'

export const unit_score: string = "Unit score"
export const rating_score: string = "Rating"
export const multiple_score: string = "Multiple Options"
export const questionaire_score: string = "Questionaire"

export const DELEGATE_ID = 'delegate_id'
export const DELEGATE_SUPER = 'delegate_super_data'
export const DELEGATE_DP = 'delegate_dp'
export const DELEGATE_DISPLAY_NAME = 'delegate_name'

export const LOCALIZATION = 'localization'

export const SUCCESS = "success"
export const ERROR = "error"


export const cropperConfig = { windowClass: "myCustomModalClass", backdrop: 'static', centered: true }
