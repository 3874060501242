/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from "@angular/core";
import { AnyAbility } from "@casl/ability";
import { AblePipe } from "@casl/angular";

@Pipe({
  name: 'module',
  standalone: true
})
export class PermissionPipe extends AblePipe<AnyAbility> implements PipeTransform {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override transform(value: any, args?: any, action?: any): any {
    if(value){
      const can = value.filter((v: any) => super.transform(v, args))
      // console.log(can, can.length, can.length > 0, value, args, action)
      return can.length > 0;
    }else{
      return true
    }
  }

}
