import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'string',
  standalone: true
})
export class StringPipe implements PipeTransform {

  transform(value: string, caseType = 'title'): string {
    if (!value) return value;
    const lowerCaseValue = value.toLowerCase();
    switch (caseType.toLowerCase()) {
      case 'uppercase':
        return lowerCaseValue.toUpperCase().trim();
      case 'lowercase':
        return lowerCaseValue.trim();
      case 'sentence':
        return lowerCaseValue.charAt(0).toUpperCase() + lowerCaseValue.slice(1).trim();
      case 'capital':
        return lowerCaseValue.replace(/\b\w/g, char => char.toUpperCase()).trim();
      case 'title':
        return this.toTitleCase(lowerCaseValue).trim()
      case 'camel':
        return this.toCamel(lowerCaseValue).trim();
      case 'uppercamel':
        return this.toPascal(lowerCaseValue).trim();
      case 'pascal':
        return this.toPascal(lowerCaseValue).trim();
      case 'kebab':
        return lowerCaseValue.replace(/\s+/g, '-').trim();
      case 'snake':
        return lowerCaseValue.replace(/\s+/g, '_').trim();
      default:
        return value.trim();
    }
  }

  /**
   * Function to convert given sting to title case
   *
   * @param value string to be coverted
   * @returns string in title case
   */
  private toTitleCase(value: string): string {
    const minorWords = ['a', 'an', 'and', 'as', 'at', 'but', 'by', 'for', 'if', 'in', 'of', 'on', 'or', 'the', 'to', 'up', 'with'];

    return value
      .split(' ')
      .map((word, index, array) => {
        // Capitalize the first and last word, and major words
        if (index === 0 || index === array.length - 1 || !minorWords.includes(word.toLowerCase())) {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        } else {
          return word.toLowerCase();
        }
      })
      .join(' ');
  }

  /**
   * Function to convert given sting to camel case
   *
   * @param value string to be coverted
   * @returns string in camel case
   */
  private toCamel(value: string): string{
    return value
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      index === 0 ? match.toLowerCase() : match.toUpperCase()
    )
    .replace(/\s+/g, '');
  }

  /**
   * Function to convert given sting to pascal case
   *
   * @param value string to be coverted
   * @returns string in pascal case
   */
  private toPascal(value: string): string{
    return value
      .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, match =>
        match.toUpperCase()
      )
      .replace(/\s+/g, '');
  }

  /**
   * Function to covert locale date to UTC
   *
   * @param date string of the date to converted.
   * @returns Date in UTC iso format.
   */
  public convertToUTC(date: string): Date{
    const selectedDate = new Date(date)
    // Combine the selected date with the current time
    const currentTime = new Date();
    const localDate = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      currentTime.getHours(),
      currentTime.getMinutes(),
      currentTime.getSeconds()
    );
    return new Date(localDate.toISOString())
  }
}
