/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventEmitter, Injectable, Output } from '@angular/core';
import { DELEGATE_DISPLAY_NAME, DELEGATE_DP, DELEGATE_ID, DELEGATE_SUPER, LOCALE_INFO, LOCALIZATION, LOGIN_INFO, PARENT_LOGIN, ROLE, SESSION_ID, USER_DISPLAY_NAME, USER_DP, USER_ID, USER_NAME, USER_SUPER, UserFetchResult } from '../data-models/user-data';
import { environment } from '../../environments/environments';
import { HttpClient, HttpHeaders, HttpParams, HttpStatusCode } from '@angular/common/http';
import { Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { StorageService } from './storage.service';
import { Observable } from 'rxjs';
import { ResponseData, STATUS_ACTIVE, STATUS_NEW, UserLogin, UserTokenFetch } from '../data-models/user-login';
import { ToastrService } from 'ngx-toastr';
import { DeviceDetectorService } from 'ngx-device-detector';
import { v4 as uuidv4 } from 'uuid';
import * as CryptoJS from 'crypto-js';
import { AbilityBuilder, PureAbility } from '@casl/ability';

export type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete';
export type Subjects = 'Article' | 'User' | 'all';

export type AppAbility = PureAbility<[Actions, Subjects]>;

// Method to obfuscate (Base64 encode) the request body
const obfuscateBase64 = (data: string) =>{
  return btoa(data);
}

// Method to unobfuscate (Base64 decode) the response body
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const unobfuscateBase64 = (data: string) =>{
  return atob(data);
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  LOGIN_INFO = LOGIN_INFO
  API_URL = `${environment.apiURL}`
  ITERATIONS = 1000                                              //the more is the number of iterations, the more secure the derived key will be
  ITERATIONS512 = 5000                                           //the more is the number of iterations, the more secure the derived key will be
  KEYLEN = 64                                                    //The required byte length
  KEYLENSMALL = 128                                              //The required byte length
  DIGEST = CryptoJS.algo.SHA1                                               //digest algorithms of string type
  DIGEST512 = CryptoJS.algo.SHA512                                         //digest algorithms of string type
  deviceInfo: any = {}
  @Output() fire = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private storage: StorageService,
    private ability: PureAbility,
    private swPush: SwPush,
    private toastr: ToastrService,
    private deviceService: DeviceDetectorService,
    // public afAuth: AngularFireAuth // Inject Firebase auth service
  ) { }

  /**
   *
   * @param flag
   */
  change(flag: boolean) {
    this.fire.emit(flag);
  }

  /**
   *
   * @returns
   */
  getEmittedValue() {
    return this.fire;
  }

  /**
   * Get the version of the Backend version.
   *
   * @returns
   */
  getVersionNo() {
    return this.http.get(this.API_URL + `/version`)
  }

  /**
   * Check if the user exists.
   *
   * @param email The email to check the user's existance.
   * */
  checkEmail(email: string): Observable<UserLogin> {
    const params = new HttpParams().append('email', email)
    return this.http.get<UserLogin>(this.API_URL + `/users-v1`, { params: params });
  }

  /**
   * Conver salt to Byte Array.
   *
   * @param salt salt to be used for encode the password.
   * @returns byte array.
   */
  convertSaltToByte(salt: string) {
    const byteCharacters = salt.split("!@");
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters[i];
    }
    const byteArray = new Uint8Array(byteNumbers);
    return byteArray
  }

  /**
   * Conver salt to Byte Array.
   *
   * @param salt salt to be used for encode the password.
   * @returns byte array.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  convertByteToSalt(salt: any) {
    const length = salt.length;
    let conv_salt = ""
    for (let i = 0; i < length; i++) {

      if (i == 0)
        conv_salt = conv_salt.concat("" + salt[i]);
      else
        conv_salt = conv_salt.concat("!@" + salt[i]);
    }
    return conv_salt
  }

  // Convert Uint8Array to Hex string
  uint8ArrayToHex(uint8Array: Uint8Array): string {
    return Array.from(uint8Array)
      .map(byte => byte.toString(16).padStart(2, '0'))
      .join('');
  }

  // Convert Hex string to Word Array
  hexToWordArray(hex: string): CryptoJS.lib.WordArray {
    return CryptoJS.enc.Hex.parse(hex);
  }

  /**
   * Generate Hash for unique identification.
   *
   * @param password password entered by the user to be hashed.
   * @param salt salt for hashing.
   * @param ITERATIONS number of iteration used.
   * @param KEYLEN CryptoJS expects the key size in words (32-bit chunks).
   * @param HASHER SHA hasher used.
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pbkdf2Sync(password: string, salt: Uint8Array, ITERATIONS: any, KEYLEN: any, HASHER: any): string {
    // Convert password to byte array
    const passwordBytes = CryptoJS.enc.Utf8.parse(password);

    // Convert Uint8Array salt to hex and then to CryptoJS WordArray
    const saltHex =this.uint8ArrayToHex(salt);
    const saltBytes = this.hexToWordArray(saltHex);

    // Perform PBKDF2 key derivation
    const derivedKey = CryptoJS.PBKDF2(passwordBytes, saltBytes, {
      keySize: KEYLEN / 4, // CryptoJS expects the key size in words (32-bit chunks)
      iterations: ITERATIONS,
      hasher:  HASHER// Use SHA-256 for hashing
    });

    // Convert the derived key to a hex string
    const derivedKeyHex = derivedKey.toString(CryptoJS.enc.Hex);
    return saltHex + ':' + derivedKeyHex;
  }

  /**
   * Change byte to hex string.
   *
   * @param bytes array of byte to be converted.
   * @returns converted hex string.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private bytesToHex(bytes: any) {
    // eslint-disable-next-line no-var
    for (var hex = [], i = 0; i < bytes.length; i++) {
      const current = bytes[i] < 0 ? bytes[i] + 256 : bytes[i];
      hex.push((current >>> 4).toString(16));
      hex.push((current & 0xF).toString(16));
    }
    return hex.join("");
  }

  /**
   * Generate a hash according to the salt and password provided.
   *
   * @param salt salt for hashing.
   * @param password password entered by the user to be hashed.
   * @returns return hashed output.
   */
  generatePasswordHash(salt: Uint8Array, password: string) {
    // var pbkdf2 = require('pbkdf2')
    // var derivedKey = pbkdf2.pbkdf2Sync(password, salt, this.ITERATIONS, this.KEYLEN, this.DIGEST)
    return this.pbkdf2Sync(password, salt, this.ITERATIONS, this.KEYLEN, this.DIGEST)
    // return await this.bytesToHex(salt) +":"+this.derivePass(password, salt)
  }

  /**
   * Generate a hash for unique identification to be saved in the localStorage after login.
   *
   * @param salt salt for hashing.
   * @param password password entered by the user to be hashed.
   * @returns return hashed key.
   */
  generatePasswordKey(salt: Uint8Array, password: string) {
    // var pbkdf2 = require('pbkdf2')
    return this.pbkdf2Sync(password, salt, this.ITERATIONS512, this.KEYLENSMALL, this.DIGEST512).split(":")[1]
    // return await this.deriveKey(password, salt, this.ITERATIONS512, this.KEYLENSMALL, this.DIGEST512)
  }

  loginDataFetecher(email: string, generatedKey: any, delegate?: any) {
    localStorage.setItem(this.LOGIN_INFO, generatedKey)
    this.fetchUserId(email).subscribe({
      next:(res: any)=>{
        let user_id = res.id
        if (delegate) {
          user_id = delegate.academy_info._id
          this.createSession(delegate.user_info._id)
          this.storage.setJsonValue(DELEGATE_ID, delegate.user_info._id)
          this.storage.setJsonValue(DELEGATE_SUPER, delegate.user_info._id)
          this.storage.setJsonValue(DELEGATE_DP, delegate.user_info.basic_information.dp)
          this.storage.setJsonValue(DELEGATE_DISPLAY_NAME, delegate.user_info.basic_information.name.first_name.concat(' ').concat(delegate.user_info.basic_information.name.last_name.toString()))
          // console.log("Auth delegate")
          this.getUserRole(delegate.user_info._id, user_id)
        }
        this.fetchUserData(user_id).subscribe(user => {
          this.storage.setJsonValue(USER_SUPER, user.data)
          this.storage.setJsonValue(USER_ID, user.data._id)
          this.storage.setJsonValue(USER_DP, user.data.basic_information.dp)
          this.storage.setJsonValue(USER_DISPLAY_NAME, user.data.basic_information.name.first_name.concat(' ').concat(user.data.basic_information.name.last_name.toString()))
          this.storage.setJsonValue(USER_NAME, user.data.user_name)
          this.router.navigate(['']);
          if (!delegate) {
            this.getUserRole(user_id)
          }
        })

        this.storeLocale()

        this.getSessionID(user_id).subscribe(res => {
          if (res["response"] === "success") {
            this.storage.setJsonValue(SESSION_ID, res["data"]["_id"])
            this.createSession(user_id)
          } else {
            // console.log("Create new sessions")
            this.createSession(user_id)
          }
        })
      },
      error:(error: any)=>{
        console.error(error)
      }
    })
  }

  storeLocale() {
    const local_info: LOCALE_INFO = {
      currency: "INR",
      currency_format: "1.2-2",
      date: "MMM dd, yyyy", // output is 'Jun 15, 2015'
      locale: "en-IN",
      region: "India",
      timezone: "Asia/Kolkata",
      country: "IN",
      time: "h:mm a",
      phone: '+91',
      unit: 'Metric'
    }
    this.storage.setJsonValue(LOCALIZATION, local_info)
  }

  /**
   * Fetch user id from the database.
   *
   * @param email the entered email id from the user.
   * @returns fetched user id.
   */
  fetchUserId(email: string): Observable<UserLogin> {
    const params = new HttpParams().append('email', email)
    return this.http.get<UserLogin>(this.API_URL + `/users/id`, { params: params})
  }

  /**
   * Fetch user from the database.
   *
   * @param id user id for fetching the user data.
   * @returns user data is fetched from the database.
   */
  fetchUserData(id: string) {
    const params = new HttpParams().append('id', id.toString())
    return this.http.get<UserFetchResult>(`${this.API_URL}/user-data/profile/info`, { params: params })
  }

  /**
   * Get session id of the user.
   *
   * @param id ObjectId : user id of the academy.
   * @returns session id.
 */
  getSessionID(id: any) {
    const params = new HttpParams()
      .append("id", id)
    return this.http.get<ResponseData>(`${this.API_URL}/userprofile/get-session-info`, { params: params })
  }

  /**
   * Fuction to check if the user session is authenticated.
   *
   * @description
   * Check if the user is logged in.
   *
   * @returns true for authenticated session, false otherwise.
   */
  isLoggedIn(): boolean {
    return this.getLoginData();
  }

  /**
   * Fuction to check if the parent session is authenticated.
   *
   * @description
   * Check if the user is logged in.
   *
   * @returns true for authenticated session, false otherwise.
   */
  isParentLoggedIn(): boolean {
    const islogged = localStorage.getItem(PARENT_LOGIN)
    return islogged != null;
  }

  /**
   * Get login info from local storage.
   *
   * @returns
   */
  public getLoginData() {
    const islogged = localStorage.getItem(this.LOGIN_INFO)
    return islogged != null;
  }

  /**
   * Get the role info of a user.
   *
   * @param id
   */
  getUserRole(id: any, academy_id?: any) {
    const route = academy_id ? `${this.API_URL}/permissions/users/${id}/${academy_id}` : `${this.API_URL}/permissions/users/${id}`
    this.http.get(route).subscribe({
      next:(res: any)=>{
        const role = res["data"]?.[0] || {};
      if (role.is_enabled) {
        this.storage.setJsonValue(ROLE, {
          _id: role._id,
          role_name: role.role_name,
          permissions: role.permission_data || []
        });
      } else {
        this.toastr.warning(`Looks like Academy manager have disabled your role. You have been logged in via limited capacity. Please contact your manager to enable your User Role`, `Role disabled.`)
        this.storage.setJsonValue(ROLE, {
          _id: role._id,
          role_name: role.role_name,
          permissions: []
        });
      }
      this.updatePermissionsForUser()
      if (!academy_id) {
        window.location.reload()
      }
      //
      window.dispatchEvent(new Event("onRoleUpdate"));
      },
      error:(error)=>{
        console.error(error)
      }
    })
  }

  /**
   * Update user permissions
   *
   * @returns
   */
  public updatePermissionsForUser() {
    const { can, rules } = new AbilityBuilder<AppAbility>(PureAbility as any);
    const permissions = this.storage.getJsonValue(ROLE)?.permissions || [];
    permissions.forEach((permission: any) => {
      // can(permission.slug, permission.slug.split("-")?.[0] || 'global');
      can(permission.slug, permission.module || 'global');
    });
    this.ability.update(rules);
  }

  /**
   * @description
   * To logout from current user session.
   */
  logout() {
    localStorage.clear()
    // this.socialAuthService.signOut;
    this.router.navigate(['/login']);
  }

  /**
   * Generate a otp with specified length.
   *
   * @param len number: length of the otp.
   * @returns string: generated otp
   */
  generateOTP(len: number) {
    let otp = ""
    for (let index = 0; index < len; index++) {
      otp = otp + `${this.getRandomInt()}`
    }
    return otp
  }

  /**
   * Generate a random number.
   *
   * @returns
   */
  getRandomInt() {
    return Math.floor(Math.random() * 9);
  }

  /**
   * Sign in with Twitter
   *
   * @returns
   */
  // TwitterAuth() {
  //   return this.AuthLogin(new TwitterAuthProvider());
  // }

  /**
   * Auth logic to run auth providers
   *
   * @param provider
   * @returns
   */
  // AuthLogin(provider: TwitterAuthProvider) {
  //   return this.afAuth
  //     .signInWithPopup(provider)
  //     .then((result) => {
  //       this.doTwitterLogin(result)
  //       // console.log('You have been successfully logged in!', result);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  /**
   * Get user creds via twitter API.
   *
   * @param auth
   */
  doTwitterLogin(auth: any) {
    this.checkEmail(auth.additionalUserInfo.profile.email).subscribe(result => {
      console.log(!result.error)
      if (!result.error == false) { // email donot exist in the database.
        this.doSignUpStepOneTwitter(auth)
      }
      else if (result.active_status === STATUS_NEW) {
        this.router.navigate(['/register']);
      } else if (result.active_status === STATUS_ACTIVE) {
        this.loginViaTwitterSignUp(auth)
      }
    })
  }

  /**
   * Step one for the registration process.
   *
   * @param data JSON containing the all the login details.
   * @returns
   */
  signUpStepOne(data: any) {
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    const params = new HttpParams().append('data', JSON.stringify(data));
    return this.http.post(`${this.API_URL}/users/register`, params, config)
  }

  /**
   * Sign up via data from twitter.
   *
   * @param auth
   */
  doSignUpStepOneTwitter(auth: any) {
    const data = {
      basic_information: {
        "email": auth.additionalUserInfo.profile.email,
        "dp": auth.additionalUserInfo.profile.profile_image_url,
      },
      "twitter_id": auth.additionalUserInfo.profile.id,
      "active_status": "new",
    }
    this.storage.setJsonValue(USER_SUPER, data)
    this.signUpStepOne(data).subscribe({
      next: (res) => {
        if (res != null) {
          this.router.navigate(['/register']);
        }
      },
      error: (e) => console.error(e),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      complete: () => {}
    })
  }

  /**
   * Login via Twitter
   *
   * @param auth
   */
  loginViaTwitterSignUp(auth: any) {
    localStorage.setItem(this.LOGIN_INFO, auth)
    this.loginDataFetecher(auth.additionalUserInfo.profile.email, auth)
    // this.fetchUserId(auth.additionalUserInfo.profile.email).subscribe((res) => {
    //   this.fetchUserData(res.id).subscribe(user => {
    //     this.storage.setJsonValue(USER_SUPER, user.data)
    //     this.storage.setJsonValue(USER_ID, user.data._id)
    //     this.storage.setJsonValue(USER_DP, user.data.basic_information.dp)
    //     this.storage.setJsonValue(USER_DISPLAY_NAME, user.data.basic_information.name.first_name.concat(' ').concat(user.data.basic_information.name.last_name.toString()))
    //     this.storage.setJsonValue(USER_NAME, user.data.user_name)
    //     window.location.reload();
    //     this.getUserRole(user.data._id)
    //   })

    //   this.getSessionID(res.id).subscribe(res => {
    //     if (res.response === "success") {
    //       this.storage.setJsonValue(SESSION_ID, res.data["_id"])
    //     }
    //   })
    // })
  }

  /**
   * Do login via google apis.
   *
   * @param credential encrpyted  auth details to be saved.
   * @param arg1 decrypted data with basic info.
   */
  doGoogleLogin(credential: any, arg1: any) {
    localStorage.setItem(this.LOGIN_INFO, credential)
    this.loginDataFetecher(arg1.email, credential)
  }

  /**
   * Do login via facebook apis.
   *
   * @param user basic user details.
   */
  // doFacebookLogin(user: SocialUser) {
  //   localStorage.setItem(this.LOGIN_INFO, user.authToken)
  //   this.loginDataFetecher(user.email, user.authToken)
  // }

  /**
   * Encrypt plain text password.
   * @param plainText string plain text password
   * @param key secret_key
   * @returns encrpyted string
   */
  encryptText(plainText: string, key: string): string {
    const keyHash = CryptoJS.enc.Hex.parse(CryptoJS.SHA256(key).toString());
    const iv = CryptoJS.lib.WordArray.random(16); // 16-byte IV for CBC mode

    const encrypted = CryptoJS.AES.encrypt(plainText, keyHash, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    // Return IV + ciphertext, encoded in Base64
    return CryptoJS.enc.Base64.stringify(iv.concat(encrypted.ciphertext));
  }

  /**
   * New function to login via server side authentication rather than client side auth.
   * @param email email of the user to be identified.
   * @param password plain text password.
   */
  attemptLogin(email: string, password: string, keep_session?: boolean, delegate?: any){
    const generatepass = this.encryptText(password, environment.secret_key)
    const enc_password = obfuscateBase64(generatepass)
    const data = {
      email: email,
      password: enc_password,
    }
    this.newServerLogin(data).subscribe({
      next:()=>{
        this.toastr.success("Login successfull!", 'Logged In', {
          progressBar: true,
          closeButton: true
        })
        // console.log(res)
        if (delegate) {
          this.doLogin(email, enc_password, delegate)
        } else {
          this.doLogin(email, enc_password)
        }
      },
      error:(error: any)=>{
        if(error.status == HttpStatusCode.NotFound){
          this.toastr.error(error.error.message, 'Wrong Password', {
            progressBar: true,
            closeButton: true
          })
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      complete:()=>{},
    })
  }

  newServerLogin(data: any){
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    return this.http.post(`${this.API_URL}/users`, data, config)
  }

  /**
   * Login validation
   *
   * @param email The email to check the user.
   * @param password Provide password from client side
   * @param user User login info from the server side
   * */
  login(email: string, password: string, user: UserLogin, keep_session?: boolean, delegate?: any) {
    const salt = this.convertSaltToByte(user.salt.toString())
    const generatePass = this.generatePasswordHash(salt, password)
    const generatedKey = this.generatePasswordKey(salt, password)
    // console.log(password, salt)
    // console.log("generatePass:", generatePass)
    // console.log("generatedKey:", generatedKey)
    // console.log("password:", user.password)
    // console.log(generatePass == user.password)

    if (user.password.length == 0 || user.salt.length == 0) {
      this.toastr.warning("Please wait till Dartle personal has entered your data", 'Warning', {
        progressBar: true,
        closeButton: true
      })
    }

    if (generatePass === user.password) {
      this.toastr.success("Login successfull!", 'Logged In', {
        progressBar: true,
        closeButton: true
      })
      if (delegate) {
        this.doLogin(email, generatedKey, delegate)
      } else {
        this.doLogin(email, generatedKey)
      }

    } else {
      this.toastr.error("The password was incorrect. Please try again.", 'Wrong Password', {
        progressBar: true,
        closeButton: true
      })
    }
  }

  /**
   * Do nessasary action after successful session validation
   * @description
   * Fetch user informations.
   *
   * Save nessasary info to the storage.
   *
   * @param email to fetch user info from the server.
   * @param generatedKey token to authenticate successful login.
   */
  doLogin(email: string, generatedKey: any, delegate?: any) {
    this.loginDataFetecher(email, generatedKey, delegate)
  }

  public getIPAddress() {
    return this.http.get('https://jsonip.com');
  }

  createSession(id: any) {
    this.deviceInfo['info'] = this.deviceService.getDeviceInfo();
    this.deviceInfo['isMobile'] = this.deviceService.isMobile();
    this.deviceInfo['isTablet'] = this.deviceService.isTablet();
    this.deviceInfo['isDesktopDevice'] = this.deviceService.isDesktop();
    const myId = uuidv4();
    const device_info = `Model:${this.deviceInfo['info'].userAgent}~!~Device:${this.deviceInfo['info'].deviceType}~!~Manufacturer:${this.deviceInfo['info'].os_version}~!~Board:${this.deviceInfo['info'].os_version}~!~Brand:${this.deviceInfo['info'].browser}~!~Serial:${this.deviceInfo['info'].browser_version}~!~UniqueId:${myId}`
    this.getIPAddress().subscribe((res: any) => {
      const session = {
        userid: id,
        deviceid: myId,
        ipaddress: res.ip,
        devicename: device_info,
        start_time: new Date(),
        lastupdate: new Date(),
        clicks: 1,
        validity: 1,
      }
      const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') }
      this.http.post(`${this.API_URL}/session_tracker/${id}`, session, config).subscribe(() => {
        // console.log("Created session")
      })
    });
  }

  getUserDelegates(id: any) {
    return this.http.get(`${this.API_URL}/login/roles/${id}`)
  }

  /**
   * Check whether the logged in user is a delegate or not.
   *
   * @returns
   */
  isDelegateLoggedIn(): boolean {
    const islogged = this.storage.getJsonValue(DELEGATE_ID)
    return islogged != null;
  }

  /**
   * Check if the user exists.
   *
   * @param username The username to check the user's existance.
   * */
  checkUsername(username: string): Observable<UserLogin> {
    const params = new HttpParams().append('username', username)
    return this.http.get<UserLogin>(this.API_URL + `/users/register/checkusername`, { params: params });
  }

  /**
   * Generate Token valid for 24h
   *
   * @param email email to reset password
   * @returns token as string
   */
  generateToken(email: any) {
    const params = new HttpParams().append('email', email)
    return this.http.get<UserTokenFetch>(this.API_URL + `/token/generate`, { params: params })
  }

  /**
   * Sents a email to the user to reset the password.
   *
   * @param token one-time string of token.
   * @param email email to the user.
   * @returns empty
   */
  sendMailToResetPassword(token: any, email: any) {
    const params = new HttpParams().append('email', email)
      .append("token", token)
    return this.http.get(this.API_URL + `/token/send`, { params: params })
  }

  changePassword(id : any, password : any) {
    const salt = this.storage.getSalt();
    const pass = this.generatePasswordHash(salt, password)
    const newSalt = this.convertByteToSalt(salt)
    const data = {
      "_id": id,
      "salt": newSalt,
      "password": pass,
    }

    const httpParams = new HttpParams()
      .append("data", JSON.stringify(data))
    return this.http.post(this.API_URL + `/changeUserAcademy`, httpParams);
  }

  changePasswordNotAcademy(id : any, password : any) {
    const salt = this.storage.getSalt();
    const pass = this.generatePasswordHash(salt, password)
    const newSalt = this.convertByteToSalt(salt)
    const data = {
      "_id": id,
      "salt": newSalt,
      "password": pass,
    }

    const httpParams = new HttpParams()
      .append("data", JSON.stringify(data))
    return this.http.post(this.API_URL + `/changeUser`, httpParams);
  }

  /**
   * Verify the token.
   *
   * @param token string token.
   * @returns valid or not.
   */
  verifyToken(token: any) {
    const params = new HttpParams().append("token", token)
    return this.http.get(this.API_URL + `/token/verify`, { params: params })
  }

  /**
   * Chnage the parental consent
   *
   * @param id user id of the Academy
   * @param status status of the consent true/false
   * @param child id of the child
   * @returns
   */
  changeParentConsent(id: any, status: any, child: any) {
    return this.http.get(this.API_URL + `/token/${id}/${child}/${status}`)
  }
}
