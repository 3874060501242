import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
  name: 'safe',
  standalone: true
})
export class SafePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(url: any): unknown {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
