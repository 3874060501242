export const environment = {
  version: '2.0.0.b07',
  production: true,
  environmentName: 'dev',
  base: 'https://academy-dev.dartle.app',
  apiURL: 'https://api-dev.dartle.app', // Dev V2
  apiPORT: '9090',
  secret_key: 'D@rtLeAc@d4m7P@ssw0rd',
  pincode_api: '114c42e0-a5c3-11ec-8480-97115668029c',
  region: 'ap-south-1',
  Bucket: 'sportlightusers',
  Lesson_Bucket: 'sportlightlessonplan',
  accessKeyId: 'AKIA5X5P22XOPSPJX4X2',
  secretAccessKey: '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',
  pwa_url: 'https://community-dev.dartle.app/',
  storage_user: 'sportlightuserdev',
  storage_lesson: 'sportlightlessonplandev',
  container_user: 'sportlight-user',
  container_lesson: 'sportlight-lessonplan',
  firebase: {
    apiKey: "AIzaSyB59eTEnk3xSRQNEE3iGJ47MheNSCOW3LE",
    authDomain: "dartle-pwa-23fa1.firebaseapp.com",
    projectId: "dartle-pwa-23fa1",
    storageBucket: "dartle-pwa-23fa1.appspot.com",
    messagingSenderId: "765391156842",
    appId: "1:765391156842:web:e9e6edf57d0670b52a3c13",
    measurementId: "G-Q9PZV69R4V"
  }
  // accessKeyId: 'AKIA5X5P22XOPSPJX4X2',
  // secretAccessKey: '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',
};
