import { CurrencyPipe } from '@angular/common';
import { inject, Pipe, PipeTransform } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { LOCALIZATION } from '../data-models/user-data';

@Pipe({
  name: 'currencyLocale',
  standalone: true
})
export class CurrencyLocalePipe implements PipeTransform {

  private currency = inject(CurrencyPipe);
  private storage = inject(StorageService)

  transform(
    value: string | number,
    symbol?: string,
    format = '1.2-2',
    locale?: string
    ): string | null {

    const localization = this.storage.getJsonValue(LOCALIZATION)
    // console.log(localization)
    if (value === null || value === undefined) {
      return null;
    }
    if(format == null || format === undefined|| format === ''){
      format = localization.currency_format
    }
    if(symbol == null || symbol === undefined|| symbol === ''){
      symbol = localization.currency
    }
    if(locale == null || locale === undefined|| locale === ''){
      locale = localization.locale
    }

    return this.currency.transform(value, symbol, 'symbol', format, locale)
  }

}
