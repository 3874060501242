/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-wrapper-object-types */
export interface UserLogin {
    id: String,
    password: String,
    salt: String,
    active_status: String,
    profile_type: String[]
    error: any
}

export interface UserTokenFetch {
    token: String,
}

export interface UserTokenValidate {
    err: String,
    token: Token,
}


export interface Token {
    Signature: String,
    Valid: boolean,
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface Profiles {

}

export interface ResponseData {
    data: any,
    response: any
}

export const PASSWORD_MIN_LENGTH = 8

export const STATUS_ACTIVE = "active"
export const STATUS_NEW = "new"
export const STATUS_DEACTIVE = "deactivate"
export const STATUS_DELETED = "deleted"
export const STATUS_INACTIVE = "inactive"
export const STATUS_DUMMY = "dummy"
export const STATUS_PENDING_CONFIRM = "pending verification"
export const STATUS_PENDING_PASSWORD_GENERATE = "pending password generation"
export const STATUS_PENDING_CONSENT = "pending consent"
export const STATUS_PENDING_PASSWORD = "pending password generation"


export const PROFILE_TYPE_PARENT = "Parent"
