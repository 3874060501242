import { DatePipe } from '@angular/common';
import { inject, Pipe, PipeTransform } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { LOCALIZATION } from '../data-models/user-data';
import { TIMEZONE } from '../data-models/date-timezones';

@Pipe({
  name: 'dateTime',
  standalone: true,
})
export class DateTimePipe implements PipeTransform {

  private datePipe = inject(DatePipe);
  private storage = inject(StorageService)

  /**
   * Custom pipe to handle date time convesions.
   * @param value given date from database.
   * @param format desired date time format. refer angular date pipe for more info.
   * @param timezone desired timezone to add to GMT
   * @param locale desired locale, used to format the date in accordance to locale information.
   * @returns
   */
  transform(
    value: string | number | Date | null | undefined,
    format?: string,
    timezone?: string,
    locale?: string
  ): string | null {
    const localization = this.storage.getJsonValue(LOCALIZATION)
    // console.log(localization, value, format, timezone, locale)
    if (value === null || value === undefined) {
      return null;
    }
    if(format == null || format === undefined|| format === ''){
      format = localization.date
    }
    if(timezone == null || timezone === undefined|| timezone === ''){
      timezone = localization.timezone
    }
    if(locale == null || locale === undefined|| locale === ''){
      locale = localization.locale
    }
    const timezone_after_UTC = TIMEZONE[timezone as keyof typeof  TIMEZONE]
    return this.datePipe.transform(value, format, timezone_after_UTC, locale);
  }


}
